<template>
  <div id="pickpacklist">
    <div class="top-line word-spacing-normal">
      <h4>Pick pack list</h4>
    </div>

    <div class="box small-font">
      <div class="item-box-1_2">
        <img 
          id="logo" 
          :src="require('@/assets/logo-header.png')"
          alt="imagen Logo"
        />
      </div>
      <div class="item-box-1 word-spacing-normal-txt xsmall-font">
        <p>
          <strong>{{ order.distribuidor.nombre_distribuidor }}</strong><br>
          {{ order.distribuidor.datos_contacto.primer_nombre }} {{ order.distribuidor.datos_contacto.apellido_paterno }} <br>
          {{ order.distribuidor.datos_contacto.email }} <br>
          {{ order.distribuidor.datos_contacto.telefono_movil }}
        </p>
      </div>
      <div class="item-box-1-1_2 word-spacing-normal-txt">
        <p><strong>No. Orden:</strong> {{ order.numero_orden }}</p>
        <p><strong>Fecha:</strong> {{ order.fecha_elaboracion.substring(0, 10) }} </p>
        <p><strong>Tipo de envio:</strong> {{ order.tipo_envio.dato }}</p>
        <p><strong>Cliente:</strong> {{ order.cliente.datos_personales.primer_nombre }} {{ order.cliente.datos_personales.apellido_paterno }}</p>
      </div>
    </div>

    <div class="box small-font">
      <div class="item-box-1-1_2 word-spacing-normal-txt">
        <p><strong>Dirección de envio:</strong></p>
        <p>
          Calle {{ order.direccion_envio.calle }} #{{ order.direccion_envio.numero_exterior }}-{{ order.direccion_envio.numero_interior }}, 
          Col. {{ order.direccion_envio.colonia }}, 
          C.P. {{ order.direccion_envio.codigo_postal }}, 
          {{ order.direccion_envio.ciudad }}, 
          {{ order.direccion_envio.estado }} 
        </p>
      </div>
      <div class="item-box-1-1_2 word-spacing-normal-txt">
        <p><strong>Datos de facturación:</strong></p>
        <p>
          {{ order.cliente.datos_fiscales[0].nombre_o_razon_social }}<br>
          {{ order.cliente.datos_fiscales[0].tax_id }}
        </p>
      </div>
    </div>
    <br>
    <table class="small-font borderless">
      <thead>
        <tr>
          <th 
            class="border-title"
            v-for="title in titles"
            :key="title.id"
            :width="title.width"
          >
            {{ title.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="detalle in order.detalles"
          :key="detalle.id"
        >
          <td class="border-cell" width="96px">{{ detalle.producto.sku }}</td>
          <td class="border-cell" width="288px">{{ detalle.producto.descripcion_corta }}</td>
          <td class="border-cell" width="192px">{{ detalle.unidades }}</td>
        </tr>
      </tbody>
    </table>
    <div class="box small-font">
      <div class="item-box-2"></div>
      <div class="item-box-1">
        <p><strong>Total productos:</strong> {{ totalProducts }}</p>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      titles: [
        {
          id: 'skt',
          name: 'SKU',
          width: '96px',
        },
        {
          id: 'prt',
          name: 'Producto',
          width: '288px',
        },
        {
          id: 'cat',
          name: 'Cant.',
          width: '192px',
        },
      ]
    }
  },
  computed: {
    totalProducts() {
      let total = 0
      this.order.detalles.forEach(detail => {
        total = total + parseFloat(detail.unidades)
      });
      return total.toFixed(2)
    }
  }
}
</script>

<style scoped>
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');*/

#pickpacklist {
  font-family: 'Montserrat', sans-serif;
}
.top-line {
  text-align: center;
  vertical-align: center;
  background-color: darkgray;
  height: 20px;
  width: 576px;
  color: white;
}
.box {
  display: flex;
  width: 576px;
}
.item-box_1_4 {
  width: 48px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: antiquewhite;*/
}
.item-box_1_2 {
  width: 96px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: blue;*/
}
.item-box {
  width: 192px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: blueviolet;*/
}
.item-box-1 {
  width: 192px;
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 20px;
  /*background-color: blueviolet;*/
}
.item-box-2 {
  width: 384px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: brown;*/
}
.item-box-3 {
  width: 576px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: chartreuse;*/
}
.item-box-1-1_2 {
  width: 288px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: coral;*/
}
.item-box-3_4 {
  width: 144px;
  padding-top: 20px;
  padding-right: 20px;
  /*background-color: crimson;*/
}
.item-box-2-1_2 {
  width: 480px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  /*background-color: darkgoldenrod;*/
}
.small-font {
  font-size: 0.7rem;
}
.xsmall-font {
  font-size: 0.6rem;
}
.word-spacing-normal {
  word-spacing: 'normal';
}
.word-spacing-normal-txt {
  word-spacing: 'normal';
}
.word-spacing-normal-table {
  word-spacing: 'normal';
}
.borderless {
  border-collapse: collapse;
  border: none;
}
img {
  margin-top: 20px;
  width: 120.8px;
  height: 66.5px;
  /*background-color: deeppink;*/
}
table {
  width: 576px;
}
thead {
  background-color: #ddd;
  text-align: left;
  height: 35px;
}
.border-title {
  border-bottom: 2px solid darkgray;
}
.border-cell {
  border-bottom: 1px solid #ddd;
}
</style>